
import DeleteUserDialog from "@/components/ui/Modal/Dialog/instances/DeleteUserDialog.vue";
import AccountStatus from "@/components/User/AccountStatus.vue";
import BackButton from "@/components/ui/Buttons/BackButton.vue";
import { computed, ComputedRef, defineComponent, watch } from "vue";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import { IUserLoginHistory } from "@/store/interfaces/accounts/IUserAccount";
import { useAccount } from "@/hooks/admin/useAdmin";
import AccountInfo from "@/components/User/AccountInfo.vue";

type UserEditData = {
  email: string;
  fullName: string;
  id: number;
};

export default defineComponent({
  name: "Admin",
  components: {
    AccountInfo,
    BackButton,
    AccountStatus,
    DeleteUserDialog
  },
  setup() {
    const store = useStore();
    const router = useRoute();

    const viewConfiguration: { page: number; perPage: number } = {
      page: 1,
      perPage: 1000
    };
    const userId = Number(router.query.id);

    const userLogins: ComputedRef<IUserLoginHistory[]> = computed(
      () => store.getters.userLogins
    );
    const options = { viewConfiguration, userId };
    const { getUserInfo, removeUser, uploadAvatar, editUser } = useAccount(
      options
    );

    // Watch routers
    watch(router, route => {
      let { path } = route;
      if (path === "/admin") {
        getUserInfo();
      }
    });

    // Call functions
    getUserInfo(); // fetch accounts on page created

    return {
      userInformation: computed(() => store.getters.userInformation),
      userLogins,
      getUserInfo,
      removeUser,
      uploadAvatar,
      editUser,
      userId
    };
  },
  data() {
    return {
      name: "",
      editable: false,
      deleteUserDialogShown: false,
      deleteLoading: false,
      permissions: 0,
      id: 0,
      avatar: "",
      editData: {
        email: "",
        fullName: ""
      } as UserEditData
    };
  },
  computed: {
    loginHistory(): {} {
      return this.userLogins.map(login => ({
        deviceName: login.device,
        deviceOS: login.client,
        deviceType: login.client === "web" ? "desktop" : "phone",
        connectLocation: login.clientIP,
        connectDate: login.createdAt
      }));
    },
    emailVerified(): boolean | null | undefined {
      return this.userInformation["emailVerified"];
    },
    blocked(): boolean | null | undefined {
      return this.userInformation["blocked"];
    },
    userName(): string | null | undefined {
      return (
        this.userInformation["firstName"] +
        " " +
        this.userInformation["lastName"]
      );
    },
    userEmail(): string | null | undefined {
      return this.userInformation["email"];
    },
    userImage(): string | null | undefined {
      return this.userInformation["image"];
    }
  },
  watch: {
    userInformation: {
      handler(data) {
        this.editData.email = data.email;
        this.editData.fullName = data.fullName;
        this.editData.id = this.userId;
      },
      deep: true
    }
  },
  methods: {
    mapUserInfo() {},
    async deleteUser(): Promise<void> {
      this.deleteLoading = true;
      const success = await this.removeUser();
      if (!success) {
        // await this.$router.push("/admins");
      }
    },
    async fileUpload(avatar: any): Promise<void> {
      this.avatar = avatar;
    },
    async endEdit() {
      this.editable = false;
      if (this.avatar !== "") {
        await this.uploadAvatar(this.avatar);
      }
      if (this.editData.id) {
        await this.editUser(this.editData);
      }
    }
  }
});
