import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "container admin-page" }
const _hoisted_2 = { class: "d-flex align-items-center" }
const _hoisted_3 = { class: "me-4 pe-1" }
const _hoisted_4 = { class: "page-body mt-4 pt-3 pb-5" }
const _hoisted_5 = { class: "row" }
const _hoisted_6 = { class: "col-6" }
const _hoisted_7 = { class: "col-6" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DeleteUserDialog = _resolveComponent("DeleteUserDialog")!
  const _component_BackButton = _resolveComponent("BackButton")!
  const _component_AccountInfo = _resolveComponent("AccountInfo")!
  const _component_AccountStatus = _resolveComponent("AccountStatus")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_DeleteUserDialog, {
      opened: _ctx.deleteUserDialogShown,
      "user-email": _ctx.userEmail,
      "user-name": _ctx.userName,
      "user-photo": _ctx.userImage,
      loading: _ctx.deleteLoading,
      onHide: _cache[0] || (_cache[0] = ($event: any) => (_ctx.deleteUserDialogShown = false)),
      onConfirm: _ctx.deleteUser
    }, null, 8, ["opened", "user-email", "user-name", "user-photo", "loading", "onConfirm"]),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createVNode(_component_BackButton)
        ]),
        _createElementVNode("h1", null, _toDisplayString(_ctx.userName), 1)
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("div", _hoisted_6, [
            _createVNode(_component_AccountInfo, {
              "user-type": 'Admin',
              "user-id": _ctx.userId,
              "user-information": _ctx.userInformation,
              onUploadAvatar: _ctx.uploadAvatar,
              onEditUser: _ctx.editUser
            }, null, 8, ["user-id", "user-information", "onUploadAvatar", "onEditUser"])
          ]),
          _createElementVNode("div", _hoisted_7, [
            _createVNode(_component_AccountStatus, {
              "blocked-user": _ctx.blocked,
              "email-verified": _ctx.emailVerified,
              onDelete: _cache[1] || (_cache[1] = ($event: any) => (_ctx.deleteUserDialogShown = true))
            }, null, 8, ["blocked-user", "email-verified"])
          ])
        ])
      ])
    ])
  ], 64))
}